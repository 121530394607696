import React, { useState } from 'react';
//import './cat.css';
import { useParams } from 'react-router-dom';
import {useQuery} from '@apollo/client';
import { SUBCATEGORY } from '../../components/gpl/gpl';
import {Loading1, SubCatList, SectionDivider} from '../../components/utility/utility';
import {Helmet} from "react-helmet";

export default function Cat(props) { 
let { catid } = useParams();
const [formState, setFormState] = useState({ catName: '', });
function updateCatName (x){setFormState({ ...formState, catName:x });}  
const currCat = useQuery(SUBCATEGORY, {variables: { id: (parseInt(catid) ? parseInt(catid) : 0) }, fetchPolicy: "cache-and-network" }); 
let listCurr= <Loading1 />; 

 
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, 
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
function setName(a,b){
    
if(a == catid && formState.catName != b){updateCatName (b)}

}

if(currCat.data) { 
let numbers = currCat.data.categories.nodes;
listCurr = numbers.map(number =>
// Correct! Key should be specified inside the array.
<section key={uuidv4()} className=""><SubCatList name={number.name}
categoryId={number.categoryId} posts={number.posts} catid={catid}
/>{setName(number.categoryId,number.name)}</section>);}

return (
<section className='defMrg mt-5'>
<SectionDivider title={formState.catName} />
<section className="mt-3">{listCurr}</section>
<Helmet>
<title>{formState.catName}</title>
</Helmet>
</section>);}

