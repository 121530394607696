import React, { useState, useRef, useEffect } from 'react';
//import './cat.css';
import { useLocation, Link, useNavigate, useParams } from 'react-router-dom';
import {useQuery} from '@apollo/client';
import { CATEGORY } from '../../components/gpl/gpl';
import {Loading1, CatList, SectionDivider} from '../../components/utility/utility';
import {Helmet} from "react-helmet";

export default function Cat(props) { 
let { catid } = useParams();
const [formState, setFormState] = useState({ catName: '', });
function updateCatName (x){setFormState({ ...formState, catName:x });}  
const currCat = useQuery(CATEGORY, {variables: { id: (parseInt(catid) ? parseInt(catid) : 0) }, fetchPolicy: "cache-and-network" }); 
let listCurr= <Loading1 />; 



if(currCat.data) { 
//let categoryName = currCat.data.posts.nodes.categories.nodes;
let categoryName = currCat.data.posts.nodes;

for (let i = 0; i < categoryName.length; i++) {
if (typeof categoryName[1].categories.nodes === 'undefined') {
    if (formState.catName != 'No result found') { 
        updateCatName ('No result found');}
    listCurr = '';}
else {    
if (categoryName[1].categories.nodes.length == 0 && formState.catName != 'No result found') { 
updateCatName ('No result found');}
for (let m = 0; m < categoryName[1].categories.nodes.length; m++) {
    if (categoryName[1].categories.nodes[m].categoryId == catid && formState.catName != categoryName[1].categories.nodes[m].name) { 
        updateCatName (categoryName[1].categories.nodes[m].name);
        break;
     }
     if (typeof categoryName[1].categories.nodes[m].categoryId === 'undefined') {
        if (formState.catName != 'No result found') { 
            updateCatName ('No result found');}
        listCurr = '';}
    }
    }
}

let numbers = currCat.data.posts.nodes;
listCurr = numbers.map(number =>
// Correct! Key should be specified inside the array.
<section key={uuidv4()} className="mb-5 mt-4"><CatList img={(number.featuredImage != 'undefined' && number.featuredImage != null ? number.featuredImage.node.sourceUrl : null)} title={number.title} id={number.postId} /></section>    
);}

 
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, 
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
return (
<>
<section className='defMrg mt-5'>
<SectionDivider title={formState.catName} />
<section className="d-flex gap-5 mt-4 mb-4 flex-wrap justify-content-center">{listCurr}</section>


<Helmet>
<title>{formState.catName}</title>
</Helmet>
</section>
</>
);}