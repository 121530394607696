import React from "react";
import { Route , Routes} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import { GENERALSETTINGS, GETPRIMARY } from '../../components/gpl/gpl';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import Home from '../../components/home/home';
import Cat from '../../components/cat/cat';
import SubCat from '../../components/subcat/subcat';
import {Content, Article} from '../../components/utility/utility';
import Logo from '../../assets/logo.png';


export default function Container(props) {  
const siteMeta = useQuery(GENERALSETTINGS, { fetchPolicy: "cache-and-network" }); 
const sitePrimary = useQuery(GETPRIMARY, { fetchPolicy: "cache-and-network" }); 

if(siteMeta.error || sitePrimary.error){ return <section className="alert alert-warning offline" role="alert">Connection Error! </section>;} 
if(siteMeta.data && sitePrimary.data) {
return ( 
<>
<Header primary={sitePrimary} siteMetaTitle={siteMeta.data.allSettings.generalSettingsTitle} siteMetaDescription={siteMeta.data.allSettings.generalSettingsDescription} />
<Routes> 
<Route exact path="/cat/:catid/*" element={<Cat {...props} siteMetaTitle={siteMeta.data.allSettings.generalSettingsTitle} siteMetaDescription={siteMeta.data.allSettings.generalSettingsDescription} />} />
<Route exact path="/subcat/:catid/*" element={<SubCat {...props} siteMetaTitle={siteMeta.data.allSettings.generalSettingsTitle} siteMetaDescription={siteMeta.data.allSettings.generalSettingsDescription} />} />
<Route exact path="/con/:id/*" element={<Content {...props} siteMetaTitle={siteMeta.data.allSettings.generalSettingsTitle} siteMetaDescription={siteMeta.data.allSettings.generalSettingsDescription} />} />
<Route exact path="/article/:id/*" element={<Article {...props} siteMetaTitle={siteMeta.data.allSettings.generalSettingsTitle} siteMetaDescription={siteMeta.data.allSettings.generalSettingsDescription} />} />
<Route exact path="/" element={<Home siteMetaTitle={siteMeta.data.allSettings.generalSettingsTitle} siteMetaDescription={siteMeta.data.allSettings.generalSettingsDescription}/>} />
<Route path="*" element={<NotFound />} /></Routes>
<Footer siteMetaTitle={siteMeta.data.allSettings.generalSettingsTitle} siteMetaDescription={siteMeta.data.allSettings.generalSettingsDescription} />
</>
);
//}
//} 
}
return <><main className="h-100 w-100">
<div id="outer">
<section className="vertical-center" id="inner">
<section className="m-4"><img src={Logo} className="img-fluid" alt="Emeka Anaebonam Health and Skill Acquisition Foundation" title='Emeka Anaebonam Health and Skill Acquisition Foundation' /></section> 
<center>
<section className="spinner-border text-success loading m-2 text-center"></section> 
<section className="iniPage loading m-2 text-center">Loading, please wait</section> 
</center>
</section>
</div>
</main>
</>;
}

function NotFound(props){

    return <>not found
    </>;

}



