import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import Logo11 from '../../assets/logonew.png';
import Logo1 from '../../assets/logoImg.png';
import Button from '@mui/material/Button';
//import SearchIcon from '@mui/icons-material/Search';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {PAGEScrollDialog, SocialConnect} from '../../components/utility/utility';
//import Form from '../../components/form/form';

export default function Header(props) { 
const [formState, setFormState] = useState({ menu: true, }); 
const ref = useRef(null);
let numbers = props.primary.data.pages.nodes;
let primayMenu = numbers.map(number =>
// Correct! Key should be specified inside the array.
<Link key={uuidv4()} to={{
pathname: "/con/"+number.pageId+"/"+number.title, }}  className="mx-3 topPrimary d-none d-lg-block">{number.title}</Link>
);

 
function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  .replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, 
          v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}
return (
<>
<section className="d-flex topSocialCon pt-1 pb-1 defPad flex-row-reverse align-items-center">
<section className='mx-2'>Newsletter
</section>
</section>


<section className='defPad topBrd pb-2'>
<section className="d-flex align-items-center">
<section className='topLogo m-2'>
<Link to={{ pathname: "/"}}><img src={Logo11} className="img-fluid d-none d-lg-block logo11" alt={props.siteMetaTitle} /></Link>
<Link to={{ pathname: "/"}}><img src={Logo1} className="img-fluid d-block d-lg-none logo1" alt={props.siteMetaTitle} /></Link></section>

<section className="flex-grow-1 d-flex flex-column">
<div className="topMenu2 mb-2 fw-bold flex-fill d-flex flex-row-reverse align-items-center">
<Link to={{
pathname: "/con/22/Contact us",
}}  className="px-1 topPrimary mb-2"  title='Contact us'>
<Button variant="contained" type='button' color='success' size='small'>
Contact us
</Button>
</Link>
{primayMenu}
</div>
</section>
</section>
</section>















<section className='footerCon fw-bold pt-2 pb-2 d-block d-lg-none'>
<section className='defPad d-block d-lg-none'>
<ResponsiveDrawer />
</section>
</section>
</>
);}
const drawerWidth = 240;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));




function ResponsiveDrawer(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>	
		

<Link to={{pathname: "/",}}  onClick={handleDrawerClose} className="sideMenu1 pb-2 m-1 px-2">Home</Link>
<section className='sideMenu pb-2 m-1 px-2' onClick={handleDrawerClose}><PAGEScrollDialog id='28' title='Our team' /></section>
<section className='sideMenu pb-2 m-1 px-2' onClick={handleDrawerClose}><PAGEScrollDialog id='30' title='What we do' /></section>
<section className='sideMenu pb-2 m-1 px-2' onClick={handleDrawerClose}><PAGEScrollDialog id='26' title='Where we work' /></section>



<section className='sideMenu pb-2 m-1 px-2' onClick={handleDrawerClose}><PAGEScrollDialog id='14' title='Terms of Use' />
</section>
<section className='sideMenu pb-2 m-1 px-2' onClick={handleDrawerClose}><PAGEScrollDialog id='16' title='Cookie Policy' />
</section>
<section className='sideMenu pb-2 m-1 px-2' onClick={handleDrawerClose}><PAGEScrollDialog id='20' title='Privacy Policy' />
</section>
<section className='sideMenu pb-2 m-1 px-2' onClick={handleDrawerClose}><PAGEScrollDialog id='22' title='Contact us' />
</section>
</Drawer>
    </Box>
  );
}
