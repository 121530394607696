import { gql } from '@apollo/client';

const GENERALSETTINGS = gql`
query GeneralSettings {
allSettings {
  generalSettingsDateFormat
  generalSettingsDescription
  generalSettingsLanguage
  generalSettingsStartOfWeek
  generalSettingsTimeFormat
  generalSettingsTimezone
  generalSettingsTitle
  generalSettingsUrl
  readingSettingsPostsPerPage
  discussionSettingsDefaultCommentStatus
  discussionSettingsDefaultPingStatus
  writingSettingsDefaultCategory
  writingSettingsDefaultPostFormat
  writingSettingsUseSmilies
}}
`;


const GETPRIMARY = gql`
query GetPages {
pages(where: {categoryId: 21, orderby: {field: TITLE, order: ASC}}) {
nodes {
title
databaseId
date
pageId
guid
}
}
}
`;
const GETFOOTERLEGAL = gql`
query GetPages {
pages(where: {categoryId: 16, orderby: {field: TITLE, order: ASC}}) {
nodes {
title
databaseId
date
pageId
id
}}}
`;

const FOOTABOUT = gql`
query GetCategoryNodes {
pages(where: {categoryId: 2, orderby: {field: TITLE, order: ASC}}, first: 100) {
nodes {
pageId
title
slug
id
}}}
`;


const FOOTMEDIACENTER = gql`
query GetCategoryNodes {
categories(first: 100, where: {childOf: 3, orderby: NAME, order: ASC}) {
nodes {
id
name
categoryId
}}}
`;

const GETTHEMATICISSUESTITLE = gql`
query GetCategoryNodes {
categories(first: 100, where: {childOf: 4, orderby: NAME, order: ASC}) {
nodes {
id
name
categoryId
}}}
`;

const PAGEDETAILS = gql`
query GetPages ($id:Int!)  {
pages(where: {id: $id}) {
nodes {
title
date
id
content
}}}
`;


const COVERIMAGE = gql`
query GetPosts {
posts(where: {categoryId: 23, orderby: {field: DATE, order: DESC}}, first: 1) {
nodes {
id
title
date
postId
featuredImage {
node {
id
sourceUrl
}}}}}
`;

const CATDETAILS = gql`
query GetPosts ($id:Int!)  {
posts(where: {categoryId: $id}, orderby: {field: DATE, order: DESC}, first: 100) {
nodes {
title
date
id
postId
content
}}}
`;
const WELCOME = gql`
query GetPages {
pages(where: {orderby: {field: DATE, order: DESC}, id: 81}, first: 1) {
nodes {
id
title
date
pageId
uri     
content
}}}
`;

const HERO = gql`
query GetPosts ($id:Int!){
posts(where: {categoryId: $id, orderby: {field: DATE, order: DESC}}, first: 100) {
nodes {
id
title
postId
date
excerpt
slug
featuredImage {
node {
id
sourceUrl
}}}}}
`;

const PARTNERS = gql`
query GetPosts ($id:Int!){
posts(where: {categoryId: $id, orderby: {field: DATE, order: DESC}}, first: 100) {
nodes {
id
title
postId
date
slug
content
featuredImage {
node {
id
sourceUrl
}}}}}
`;

const LATESTBLOG = gql`
query GetPosts {
posts(
where: {orderby: {field: DATE, order: DESC}, categoryNotIn: "17"}
first: 4
) {
nodes {
id
title
date
postId
featuredImage {
node {
id
sourceUrl
}}}}}
`;

const POSTDETAILS = gql`
query GetPosts ($id:Int!)  {
posts(where: {id: $id}) {
nodes {
title
date
id
content
postId
featuredImage {
node {
id
sourceUrl
}}}}}
`;

const CATEGORY = gql`
query GetPosts($id:Int!) {
posts(where: {categoryId: $id, orderby: {field: DATE, order: DESC}}, first: 100) {
nodes {
id
title
date
postId
categories {
  nodes {
    name
    categoryId
  }
}
featuredImage {
node {
id
sourceUrl
}}}}}
`;

const SUBCATEGORY = gql`
query GetCategoryNodes($id:Int!)  {
  categories(first: 100, where: {childOf: 4}) {
    nodes {
      id
      name
      categoryId
      posts(where: {categoryId: $id, orderby: {field: DATE, order: DESC}}, first: 100) {
        nodes {
          title
          postId
          featuredImage {
            node {
              sourceUrl
            }
          }
          categories {
            nodes {
              name
              categoryId
            }
          }
        }
      }
    }
  }
}
`;
export { GENERALSETTINGS, COVERIMAGE, PAGEDETAILS, GETFOOTERLEGAL, FOOTABOUT, GETTHEMATICISSUESTITLE, FOOTMEDIACENTER, PARTNERS, CATDETAILS, WELCOME, HERO, LATESTBLOG, POSTDETAILS, GETPRIMARY, CATEGORY, SUBCATEGORY }
